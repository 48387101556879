import React from "react";
import {
  Divider,
  HStack,
  Switch,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import "@fontsource/roboto";

import * as lib from "../../lib/lib";

const Gas = ({ outboundFees, inboundAddresses, pools }) => {
  let chainToGasRate = {};
  inboundAddresses?.forEach((a) => {
    if (a.chain === "THOR") {
      chainToGasRate[a.chain] = "0";
      return;
    }
    const rate = lib.localeString(parseInt(a.gas_rate));
    chainToGasRate[a.chain] = `${rate} ${a.gas_rate_units.toLowerCase()}`;
  });

  // set asset to parsed asset
  const rows = outboundFees
    .filter((row) => !row.asset.startsWith("BNB."))
    .map((row) => {
      return {
        ...row,
        asset: lib.parseAsset(row.asset),
      };
    });

  const [tokensSwitch, setTokensSwitch] = React.useState(false);

  return (
    <>
      <HStack
        justifyContent="center"
        borderRadius="sm"
        bg="#f5f5f5"
        width="full"
        fontSize="sm"
        p={2}
        mb={3}
      >
        <Text>Tokens</Text>
        <Switch
          ml={2}
          size="sm"
          isChecked={tokensSwitch}
          onChange={() => setTokensSwitch((state) => !state)}
        />
      </HStack>
      <Divider my={2} mt={3} />
      <Table size="sm">
        <Thead>
          <Tr>
            <Th p={1} py={2} pr={3}>
              <Text>Asset</Text>
            </Th>
            <Th p={1} py={2} pr={3}>
              <Text>Fee</Text>
            </Th>
            <Th p={1} py={2} pr={3}>
              <Text>Rate</Text>
            </Th>
            <Th p={1} py={2} pr={3} textAlign="right">
              <Text>DOFM</Text>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {rows
            .filter(
              (row) =>
                row.asset.chain === row.asset.symbol ||
                (row.asset.chain === "BSC" && row.asset.symbol === "BNB") ||
                (row.asset.chain === "BASE" && row.asset.symbol === "ETH") ||
                (row.asset.chain === "GAIA" && row.asset.symbol === "ATOM") ||
                tokensSwitch === true,
            )
            .map((row, index) => (
              <Tr key={index}>
                <Th p={1} py={2} pr={3}>
                  <Text>{`${row.asset.chain}.${row.asset.symbol}`}</Text>
                </Th>
                <Td p={1} py={2} pr={3}>
                  <Text>
                    {lib.usdString(
                      lib.amountToUSD(
                        parseInt(row.outbound_fee),
                        row.asset,
                        pools,
                      ),
                      false,
                    )}
                  </Text>
                </Td>
                <Td p={1} py={2} pr={3}>
                  <Text>{chainToGasRate[row.asset.chain] || ""}</Text>
                </Td>
                <Td p={1} py={2} pr={3} textAlign="right">
                  {row.dynamic_multiplier_basis_points && (
                    <Text>
                      {(
                        parseInt(row.dynamic_multiplier_basis_points) / 10000
                      ).toFixed(2)}
                      x
                    </Text>
                  )}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </>
  );
};

export default Gas;
