import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Stat,
  StatArrow,
  Badge,
  Tooltip,
  Table,
  TableContainer,
  Tbody,
  Tr,
  Th,
} from "@chakra-ui/react";

import LPAssetStats from "./LPAssetStats";
import * as lib from "../../lib/lib";

export default function LPAccordionItem({ lp, onClick }) {
  const {
    shortAsset,
    redeemableAsset,
    redeemableRune,
    redeemableUsd,
    depositedAsset,
    depositedRune,
    depositedUsd,
    pendingUsd,
    addressRune,
    addressAsset,
  } = lp;
  const delta = redeemableUsd - depositedUsd;
  const isIncreasing = delta >= 0;
  const pctChange = isIncreasing
    ? `${lib.formatDecimal((delta / depositedUsd) * 100)}%`
    : `(${lib.formatDecimal((-delta / depositedUsd) * 100)}%)`;
  const isRuneLP = addressRune ? true : false;
  const isAssetLP = addressAsset ? true : false;

  return (
    <AccordionItem>
      <TableContainer>
        <AccordionButton onClick={onClick}>
          <Table size="sm" variant="unstyled" width="100%">
            <Tbody>
              <Tr>
                <Th width="40%" p={0} textAlign="left">
                  <Badge
                    mr={1}
                    colorScheme={isAssetLP && isRuneLP ? "green" : "purple"}
                  >
                    {isAssetLP && isRuneLP ? "Dual" : "Asym"}
                  </Badge>
                  {shortAsset}
                </Th>
                <Th width="30%" p={0} pr={2} textAlign="right">
                  <Tooltip
                    placement="bottom"
                    label={`+ pending $${lib.formatDecimal(pendingUsd)}`}
                  >
                    <Box>${lib.formatDecimal(redeemableUsd)}</Box>
                  </Tooltip>
                </Th>
                <Th width="7%" p={0} textAlign="right">
                  <Stat>
                    <StatArrow type={isIncreasing ? "increase" : "decrease"} />
                  </Stat>
                </Th>
                <Th
                  width="23%"
                  p={0}
                  textAlign="right"
                  textColor={isIncreasing ? "green.400" : "red.400"}
                >
                  {pctChange}
                  <AccordionIcon textColor="black" />
                </Th>
              </Tr>
            </Tbody>
          </Table>
        </AccordionButton>
      </TableContainer>
      <AccordionPanel>
        {isAssetLP && (
          <TableContainer>
            <LPAssetStats
              asset={shortAsset}
              address={addressAsset}
              deposited={depositedAsset}
              redeemable={redeemableAsset}
              isAsymLP={!(isAssetLP && isRuneLP)}
            />
          </TableContainer>
        )}
        {isRuneLP && (
          <TableContainer>
            <LPAssetStats
              asset={"THOR.RUNE"}
              address={addressRune}
              deposited={depositedRune}
              redeemable={redeemableRune}
              isAsymLP={!(isAssetLP && isRuneLP)}
            />
          </TableContainer>
        )}
      </AccordionPanel>
    </AccordionItem>
  );
}
