import React from "react";
import { Box } from "@chakra-ui/react";
import "@fontsource/roboto";

import Status from "../components/status/Status";
import Chains from "../components/Chains";
import Transactions from "../components/Transactions";
import Search from "../components/search/Search";

import * as lib from "../lib/lib";
import * as config from "../lib/config";

const Home = ({ network, pools }) => {
  // ------------------------------ ui state ------------------------------

  const heightRef = React.useRef(0);
  const poolsRef = React.useRef(pools);

  if (config.getHeight() > 0) {
    heightRef.current = config.getHeight();
  }

  const [width, setWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  // ------------------------------ background updates ------------------------------

  const [inboundAddresses, setInboundAddresses] = React.useState(null);
  const [mimir, setMimir] = React.useState(null);
  const inboundMimirInterval = React.useRef(null);
  React.useEffect(() => {
    const updateInboundAddressesAndMimir = async () => {
      // get mimirs and inbound addresses
      const mimir = (await lib.getThornode(`thorchain/mimir`)).data;
      let inboundAddresses = (
        await lib.getThornode(`thorchain/inbound_addresses`)
      ).data;

      // update mimir
      setMimir(mimir);

      // extend with halt heights
      inboundAddresses = inboundAddresses.map((chain) => ({
        ...chain,
        haltHeight: Math.max(
          ...Object.keys(mimir)
            .filter(
              (key) =>
                (new RegExp(`.*HALT.*${chain.chain}CHAIN`).test(key) ||
                  key === "HALTCHAINGLOBAL") &&
                mimir[key] !== 0,
            )
            .map((key) => mimir[key]),
        ),
        haltTradingHeight: Math.max(
          ...Object.keys(mimir)
            .filter(
              (key) =>
                (new RegExp(`HALT${chain.chain}TRADING`).test(key) ||
                  key === "HALTTRADING") &&
                mimir[key] !== 0,
            )
            .map((key) => mimir[key]),
        ),
        haltSigningHeight: Math.max(
          ...Object.keys(mimir)
            .filter(
              (key) =>
                (new RegExp(`HALTSIGNING${chain.chain}`).test(key) ||
                  key === "HALTSIGNING") &&
                mimir[key] !== 0,
            )
            .map((key) => mimir[key]),
        ),
        haltLPHeight: Math.max(
          ...Object.keys(mimir)
            .filter(
              (key) =>
                new RegExp(`PAUSELP${chain.chain}`).test(key) &&
                mimir[key] !== 0,
            )
            .map((key) => mimir[key]),
        ),
      }));

      setInboundAddresses(inboundAddresses);
    };

    if (!inboundMimirInterval.current) {
      updateInboundAddressesAndMimir();
      inboundMimirInterval.current = setInterval(
        updateInboundAddressesAndMimir,
        60000,
      ); // 1 minute
    }
  }, []);

  const [outboundFees, setOutboundFees] = React.useState([]);
  const outboundFeesInterval = React.useRef(null);
  React.useEffect(() => {
    const updateOutboundFees = async () => {
      const outboundFees = (await lib.getThornode(`thorchain/outbound_fees`))
        .data;
      setOutboundFees(outboundFees);
    };
    if (!outboundFeesInterval.current) {
      updateOutboundFees();
      outboundFeesInterval.current = setInterval(updateOutboundFees, 60000); // 1 minute
    }
  }, []);

  const [supply, setSupply] = React.useState(null);
  const supplyInterval = React.useRef(null);
  React.useEffect(() => {
    const updateSupply = async () => {
      const supply = (await lib.getThornode(`cosmos/bank/v1beta1/supply`)).data;
      setSupply(supply);
    };
    if (!supplyInterval.current) {
      updateSupply();
      supplyInterval.current = setInterval(updateSupply, 300000); // 5 minutes
    }
  }, []);

  const [lendingBalance, setLendingBalance] = React.useState(null);
  const lendingBalanceInterval = React.useRef(null);
  React.useEffect(() => {
    const updateLendingBalance = async () => {
      const lendingBalance = (
        await lib.getThornode(`thorchain/balance/module/lending`)
      ).data;
      setLendingBalance(lendingBalance);
    };
    if (!lendingBalanceInterval.current) {
      updateLendingBalance();
      lendingBalanceInterval.current = setInterval(
        updateLendingBalance,
        300000,
      ); // 5 minutes
    }
  }, []);

  const [runepool, setRunepool] = React.useState(null);
  const runepoolInterval = React.useRef(null);
  React.useEffect(() => {
    const updateRunepool = async () => {
      const runepool = (await lib.getThornode(`thorchain/runepool`)).data;
      setRunepool(runepool);
    };
    if (!runepoolInterval.current) {
      updateRunepool();
      runepoolInterval.current = setInterval(updateRunepool, 300000); // 5 minutes
    }
  }, []);

  const [nodes, setNodes] = React.useState(null);
  const nodesInterval = React.useRef(null);
  React.useEffect(() => {
    const updateNodes = async () => {
      const nodes = (await lib.getThornode(`thorchain/nodes`)).data;
      setNodes(nodes);
    };
    if (!nodesInterval.current) {
      updateNodes();
      nodesInterval.current = setInterval(updateNodes, 60000); // 1 minute
    }
  }, []);

  const [bifrost, setBifrost] = React.useState(null);
  const bifrostInterval = React.useRef(null);
  React.useEffect(() => {
    const updateBifrost = async () => {
      const bifrost = (await lib.getNineRealmsAPI(`thorchain/bifrost`)).data;
      setBifrost(bifrost);
    };
    if (!bifrostInterval.current) {
      updateBifrost();
      bifrostInterval.current = setInterval(updateBifrost, 60000); // 1 minute
    }
  }, []);

  const [upgrades, setUpgrades] = React.useState(null);
  const upgradesInterval = React.useRef(null);
  React.useEffect(() => {
    const updateUpgrades = async () => {
      const upgradesRes = await lib.getThornode(`thorchain/upgrade_proposals`);
      setUpgrades(upgradesRes.data);
    };

    if (!upgradesInterval.current) {
      updateUpgrades();
      upgradesInterval.current = setInterval(updateUpgrades, 60000); // 1 minute
    }
  });

  const [pending, setPending] = React.useState(null);
  const pendingInterval = React.useRef(null);
  React.useEffect(() => {
    const updatePending = async () => {
      const outboundReq = lib.getThornode(`thorchain/queue/outbound`);
      const scheduledReq = lib.getThornode(`thorchain/queue/scheduled`);
      const streamingReq = lib.getThornode(`thorchain/swaps/streaming`);
      const [outboundRes, scheduledRes, streamingRes] = await Promise.all([
        outboundReq,
        scheduledReq,
        streamingReq,
      ]);

      for (const res of [outboundRes, scheduledRes, streamingRes]) {
        const newHeight = parseInt(
          res.headers["grpc-metadata-x-cosmos-block-height"],
          10,
        );
        if (!config.getHeight() > 0) {
          heightRef.current = Math.max(heightRef.current, newHeight);
        }
      }

      let pending = [];
      if (outboundRes?.data) {
        pending = pending.concat(
          outboundRes.data.map((pending) => ({
            ...pending,
            type: "Outbound",
          })),
        );
      }
      if (scheduledRes?.data) {
        pending = pending.concat(
          scheduledRes.data.map((pending) => ({
            ...pending,
            type: "Scheduled",
          })),
        );
      }
      if (streamingRes?.data) {
        pending = pending.concat(
          streamingRes.data.map((pending) => ({
            ...pending,
            type: "Streaming",
            sourceAsset: lib.parseAsset(pending.source_asset, poolsRef.current),
            targetAsset: lib.parseAsset(pending.target_asset, poolsRef.current),
          })),
        );
      }

      pending = pending.map((pending) =>
        pending.type === "Streaming"
          ? pending
          : {
              asset: lib.parseAsset(pending.coin.asset, poolsRef.current),
              amount: pending.coin.amount,
              to: pending.to_address,
              memo: pending.memo,
              source: pending.memo.split(":")[1],
              type: pending.type,
              height: pending.height,
              vault: pending.vault_pub_key,
              cloutSpent: parseFloat(pending.clout_spent),
            },
      );

      setPending(pending);
    };

    if (!pendingInterval.current) {
      updatePending();
      pendingInterval.current = setInterval(updatePending, 20000); // 20 seconds
    }
  }, []);

  const [thornodeNetwork, setThornodeNetwork] = React.useState(null);
  const thornodeNetworkInterval = React.useRef(null);
  React.useEffect(() => {
    const updateThornodeNetwork = async () => {
      const thornodeNetwork = (await lib.getThornode(`thorchain/network`)).data;
      setThornodeNetwork(thornodeNetwork);
    };

    if (!thornodeNetworkInterval.current) {
      updateThornodeNetwork();
      thornodeNetworkInterval.current = setInterval(
        updateThornodeNetwork,
        60000,
      ); // 1 minute
    }
  }, []);

  return (
    <Box width="100%" maxWidth="3xl">
      <Search />

      <Chains
        height={heightRef.current}
        width={width}
        inboundAddresses={inboundAddresses}
      />

      <Status
        height={heightRef.current}
        mimir={mimir}
        network={network}
        thornodeNetwork={thornodeNetwork}
        outboundFees={outboundFees}
        inboundAddresses={inboundAddresses}
        pools={pools}
        supply={supply}
        lendingBalance={lendingBalance}
        runepool={runepool}
        nodes={nodes}
        upgrades={upgrades}
        bifrost={bifrost}
      />

      <Transactions
        height={heightRef.current}
        pending={pending}
        pools={pools}
      />
    </Box>
  );
};

export default Home;
