import React from "react";
import { BeatLoader } from "react-spinners";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Card,
  Center,
  ChakraProvider,
  CircularProgress,
  Divider,
  Flex,
  HStack,
  Heading,
  Image,
  Link,
  Progress,
  Spacer,
  Spinner,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Step,
  StepIcon,
  StepIndicator,
  StepSeparator,
  StepStatus,
  Stepper,
  Table,
  TableContainer,
  Tag,
  Tbody,
  Text,
  Th,
  Tr,
  VStack,
  theme,
  useSteps,
} from "@chakra-ui/react";
import { ArrowRightIcon, ExternalLinkIcon, CheckIcon } from "@chakra-ui/icons";
import "@fontsource/roboto";
import Icon from "react-crypto-icons";

import LongPressTooltip from "../components/LongPressTooltip";
import UnsupportedTransaction from "../components/UnsupportedTransaction";

import * as lib from "../lib/lib";
import * as config from "../lib/config";
import * as types from "../lib/types";

////////////////////////////////////////////////////////////////////////////////////////
// State
////////////////////////////////////////////////////////////////////////////////////////

// makeState takes all the endpoint responses and builds a state object in a structure
// which maps logically to the user interface.
//
// TODO: The status response should include the following to skip extra requests:
// - inbound finalised height
// - outbound scheduled height
// - outbound observation counts
// - outbound finalised height
// - "actions" from the details should be in status
// - last block height for the corresponding chain
function makeState(
  height,
  status,
  pools,
  actions,
  inDetails,
  outDetails,
  lastBlock,
) {
  if (!pools || !status) {
    return;
  }

  // if this is the outbound prompt to redirect
  if (inDetails?.tx.tx.memo.startsWith("OUT:")) {
    return {
      isOutbound: true,
      inbound: inDetails.tx.tx.memo.split(":")[1],
    };
  }

  const memo = status.tx ? lib.parseMemo(status.tx?.memo) : null;

  // filter affiliate txs from out_txs
  const userAddresses = new Set([
    status.tx?.from_address.toLowerCase(),
    memo?.destAddr?.toLowerCase(),
  ]);
  let outTxs = status.out_txs?.filter((tx) =>
    userAddresses.has(tx.to_address.toLowerCase()),
  );
  if (!outTxs) {
    outTxs = status.planned_out_txs
      ?.filter((tx) => userAddresses.has(tx.to_address.toLowerCase()))
      .map((tx) => ({
        ...tx,
        coins: [{ amount: tx.coin.amount, asset: tx.coin.asset }],
      }));
  }

  // extract max gas from in details actions
  const detailActions = inDetails?.actions?.filter((tx) =>
    userAddresses.has(tx.to_address.toLowerCase()),
  );
  let maxGas = 0;
  let maxGasAsset = null;
  if (detailActions?.length > 0) {
    maxGas = detailActions[0].max_gas[0].amount;
    maxGasAsset = lib.parseAsset(detailActions[0].max_gas[0].asset, pools);
  }

  const inAsset = lib.parseAsset(status.tx?.coins[0].asset, pools);
  const inAmount = parseInt(status.tx?.coins[0].amount);
  const outAsset = lib.parseAsset(
    outTxs?.length > 0 ? outTxs[0].coins[0].asset : memo?.asset,
    pools,
  );
  const outAmount = outTxs?.length > 0 && parseInt(outTxs[0].coins[0].amount);

  const outboundHasRefund = outTxs?.some(
    (tx) => tx.refund || tx.memo?.toLowerCase().startsWith("refund"),
  );
  const outboundHasSuccess = outTxs?.some((tx) =>
    tx.memo?.toLowerCase().startsWith("out"),
  );
  const outboundRefundReason = actions?.actions.find(
    (action) => action.type === "refund",
  )?.metadata.refund.reason;

  // compute confirmations
  const inChainHeight = lastBlock && inAsset && lastBlock[inAsset.chain];
  const outChainHeight = lastBlock && outAsset && lastBlock[outAsset.chain];
  const inObservedHeight =
    inDetails &&
    Math.min(...inDetails?.txs.map((tx) => tx.external_observed_height));
  const inFinalisedHeight =
    inDetails &&
    Math.max(
      ...inDetails?.txs.map((tx) => tx.external_confirmation_delay_height),
    );
  const outObservedHeight =
    outDetails &&
    Math.max(...outDetails?.txs.map((tx) => tx.external_observed_height));

  const swapAction = actions?.actions.find((action) => action.type === "swap");

  const state = {
    isSwap:
      memo?.type.toLowerCase() === "swap" ||
      memo?.type.toLowerCase() === "s" ||
      memo?.type === "=",
    memo: status.tx?.memo,
    chain: status.tx?.chain,
    inbound: {
      txid: status.tx?.id,
      from: status.tx?.from_address,
      asset: inAsset,
      amount: inAmount,
      usdValue: lib.amountToUSD(inAmount, inAsset, pools),
      gas: status.tx?.gas && status.tx.gas[0].amount,
      gasAsset:
        status.tx?.gas && lib.parseAsset(status.tx?.gas[0].asset, pools),
      observed:
        status.stages.inbound_observed?.started ||
        status.stages.inbound_observed?.completed,
      affiliate: memo?.affiliate,
      preObservations: status.stages.inbound_observed?.pre_confirmation_count,
      observations: status.stages.inbound_observed?.final_count,
      confirmations: inChainHeight - inObservedHeight,
      confirmationsRequired: inDetails && inFinalisedHeight - inObservedHeight,
      finalisedHeight: inDetails?.finalised_height,
      icon: inAsset?.symbol.toLowerCase(),
      done: status.stages.inbound_finalised?.completed,
    },
    swap: {
      limit: memo?.limit,
      affiliateFee: parseInt(swapAction?.metadata?.swap?.affiliateFee) || null,
      liquidityFee: parseInt(swapAction?.metadata?.swap?.liquidityFee) || null,
      slip: parseInt(swapAction?.metadata?.swap?.swapSlip),
      streaming: {
        count:
          status.stages.swap_status?.streaming?.count ||
          parseInt(swapAction?.metadata?.swap?.streamingSwapMeta?.count) ||
          0,
        interval:
          status.stages.swap_status?.streaming?.interval ||
          parseInt(swapAction?.metadata?.swap?.streamingSwapMeta?.interval) ||
          memo?.interval,
        memoQuantity: memo?.quantity,
        quantity:
          status.stages.swap_status?.streaming?.quantity ||
          parseInt(swapAction?.metadata?.swap?.streamingSwapMeta?.quantity) ||
          memo?.quantity,
      },
      aggregator: {
        contract: memo?.aggregatorContract,
        targetAsset: memo?.aggregatorTargetAsset,
        targetLimit: memo?.aggregatorTargetLimit,
      },
      done:
        status.stages.swap_finalised?.completed &&
        !status.stages.swap_status?.pending,
    },
    outbound: {
      txid:
        outTxs?.length > 0 && outTxs[0].chain !== "THOR" ? outTxs[0]?.id : null,
      to: (outTxs?.length > 0 && outTxs[0].to_address) || memo?.destAddr,
      asset: outAsset,
      amount: parseInt(outAmount),
      usdValue: outAmount ? lib.amountToUSD(outAmount, outAsset, pools) : null,
      fee: parseInt(swapAction?.metadata?.swap?.networkFees[0]?.amount),
      feeAsset: lib.parseAsset(
        swapAction?.metadata?.swap?.networkFees[0]?.asset,
      ),
      maxGas: maxGas,
      maxGasAsset: maxGasAsset,
      gas: outTxs?.length > 0 && outTxs[0].gas && outTxs[0].gas[0].amount,
      gasAsset:
        outTxs?.length > 0 && outTxs[0].gas
          ? lib.parseAsset(outTxs[0].gas[0].asset, pools)
          : null,
      observations:
        outDetails?.txs &&
        Math.max(...outDetails.txs.map((tx) => tx.signers.length)),
      confirmations: outDetails && outChainHeight - outObservedHeight + 1,
      finalisedHeight: outDetails?.finalised_height,
      icon: outAsset?.symbol?.toLowerCase(),
      delayBlocks: inDetails?.outbound_height - inDetails?.finalised_height,
      delayBlocksRemaining:
        Math.max(
          Math.min(
            status.stages.outbound_delay?.remaining_delay_blocks,
            inDetails?.outbound_height - height,
          ),
          0,
        ) || 0,
      done: outboundHasRefund || outboundHasSuccess,
      hasRefund: outboundHasRefund,
      hasSuccess: outboundHasSuccess,
      hasMultipleSuccess: outTxs?.length > 1,
      refundReason: outboundRefundReason,
    },
    extraOutbounds: outTxs?.slice(1).map((tx) => ({
      txid: tx.id,
      gas: tx.gas ? tx.gas[0].amount : null,
      gasAsset: tx.gas ? lib.parseAsset(tx.gas[0].asset, pools) : null,
      to: tx.to_address,
      icon: lib.parseAsset(tx.coins[0].asset, pools).symbol.toLowerCase(),
      asset: lib.parseAsset(tx.coins[0].asset, pools),
      amount: parseInt(tx.coins[0].amount),
      usdValue: lib.amountToUSD(
        parseInt(tx.coins[0].amount),
        lib.parseAsset(tx.coins[0].asset, pools),
        pools,
      ),
    })),
  };

  return state;
}

////////////////////////////////////////////////////////////////////////////////////////
// UI
////////////////////////////////////////////////////////////////////////////////////////

const Swap = ({ txid, network, pools, queryLogo }) => {
  // ------------------------------ static ------------------------------

  // ------------------------------ background intervals ------------------------------

  // used or set in effects, no need to re-render
  const heightRef = React.useRef(0);
  const startEtaRef = React.useRef(null);

  if (config.getHeight() > 0) {
    heightRef.current = config.getHeight();
  }

  // state changes triggering render
  const [actions, setActions] = React.useState(null);
  const [inboundDetails, setInboundDetails] = React.useState(null);
  const [outboundDetails, setOutboundDetails] = React.useState(null);
  const [status, setStatus] = React.useState(null);
  const [lastBlock, setLastBlock] = React.useState(null);

  // derived
  const [state, setState] = React.useState(null);
  const [eta, setEta] = React.useState(null);
  const [age, setAge] = React.useState(null);

  // ------------------------------ background updates ------------------------------

  // ---------- update last block (40s) ----------

  const lastBlockInterval = React.useRef(null);

  React.useEffect(() => {
    const update = async () => {
      // update lastblock
      const res = await lib.getThornode(`thorchain/lastblock`);
      setLastBlock(
        res.data.reduce((map, item) => {
          map[item.chain] = item.last_observed_in;
          return map;
        }, {}),
      );
      if (!config.getHeight() > 0) {
        const newHeight = parseInt(res.headers["grpc-metadata-x-cosmos-block-height"], 10);
        heightRef.current = Math.max(heightRef.current, newHeight);
      }
    };

    if (!lastBlockInterval.current) {
      update();
      lastBlockInterval.current = setInterval(update, 40000); // 40 seconds
      return () => clearInterval(lastBlockInterval.current);
    }
  });

  // ---------- update state (20s) ----------

  const updateInterval = React.useRef(null);

  React.useEffect(() => {
    if (!pools || !txid) return;

    const update = async () => {
      const statusRes = await lib.getThornode(`thorchain/tx/status/${txid}`);
      setStatus(statusRes.data);
      let newHeight = parseInt(statusRes.headers["grpc-metadata-x-cosmos-block-height"], 10);
      if (!config.getHeight() > 0) {
        heightRef.current = Math.max(heightRef.current, newHeight);
      }

      // update midgard actions for fees
      if (statusRes.data.stages.swap_finalised?.completed) {
        const actions = await lib.getMidgard(`v2/actions`, { txid: txid });
        setActions(actions.data);
      }

      // TODO: should go away after status provides finalized height
      const inDetails = await lib
        .getThornode(`thorchain/tx/details/${txid}`)
        .catch((_) => false);

      if (!inDetails) return;

      setInboundDetails(inDetails.data);
      if (!config.getHeight() > 0) {
        newHeight = parseInt(inDetails.headers["grpc-metadata-x-cosmos-block-height"], 10);
        heightRef.current = Math.max(heightRef.current, newHeight);
      }

      const partialState = makeState(
        heightRef.current,
        statusRes.data,
        pools,
        null,
        inDetails.data,
        null,
        null,
        null,
        null,
      );

      if (
        partialState?.outbound.txid &&
        partialState?.outbound.asset.chain !== "THOR"
      ) {
        const outDetails = await lib.getThornode(
          `thorchain/tx/details/${partialState?.outbound.txid}`,
        );
        setOutboundDetails(outDetails.data);
        if (!config.getHeight() > 0) {
          newHeight = parseInt(outDetails.headers["grpc-metadata-x-cosmos-block-height"], 10);
          heightRef.current = Math.max(heightRef.current, newHeight);
        }
      }
    };

    if (!updateInterval.current) {
      update();
      updateInterval.current = setInterval(update, 20000); // 20 seconds
      return () => clearInterval(updateInterval.current);
    }
  }, [pools, txid]);

  // ------------------------------ derived ------------------------------

  // ---------- state ----------

  React.useEffect(() => {
    const state = makeState(
      heightRef.current,
      status,
      pools,
      actions,
      inboundDetails,
      outboundDetails,
      lastBlock,
    );
    setState(state);

    if (state?.isOutbound) {
      return;
    }

    // stop updating if done
    if (state?.outbound.done) {
      clearInterval(updateInterval.current);
    }

    // calculate eta
    const confirmTimeRemaining =
      Math.max(
        (state?.inbound.confirmationsRequired - state?.inbound.confirmations) *
          lib.blockMilliseconds(state?.inbound.asset?.chain),
        0,
      ) || 0;
    let streamTimeRemaining = 0;
    if (state?.swap.streaming && !state?.swap.done) {
      const { quantity, count, interval } = state?.swap.streaming;
      const streamBlocksRemaining = (quantity - count) * interval;
      streamTimeRemaining =
        streamBlocksRemaining * lib.blockMilliseconds("THOR") || 0;
    }
    const outboundDelayRemaining =
      (state?.outbound.delayBlocksRemaining || 0) *
      lib.blockMilliseconds("THOR");
    const newEta =
      confirmTimeRemaining + streamTimeRemaining + outboundDelayRemaining;
    if (newEta > 0) {
      setEta((eta) => (eta > 0 || eta === null ? newEta : 0));
    }

    // calculate age
    setAge(
      (heightRef.current - state?.inbound.finalisedHeight) *
        lib.blockMilliseconds("THOR"),
    );

    // set the eta at the start for progress circle
    if (!startEtaRef.current) {
      startEtaRef.current =
        confirmTimeRemaining + streamTimeRemaining + outboundDelayRemaining;
    }
  }, [status, lastBlock, pools, actions, inboundDetails, outboundDetails]);

  // ---------- eta ----------

  const ticker = () => {
    setEta((eta) => (eta === null ? null : Math.max(0, eta - 1000)));
    setAge((age) => age + 1000);
  };
  React.useEffect(() => {
    const interval = setInterval(ticker, 1000);
    return () => clearInterval(interval);
  }, []);

  // ------------------------------ ui state ------------------------------

  const [accordionIndex, setAccordionIndex] = React.useState([]);
  const { activeStep, setActiveStep } = useSteps({
    index: 1,
    count: 3,
  });

  // ------------------------------ steps ------------------------------

  React.useEffect(() => {
    if (state?.isOutbound) return;

    let stage = 0;

    if (state?.inbound.done) {
      stage = 1;
    }
    if (state?.swap.done) {
      stage = 2;
    }
    if (state?.outbound.done) {
      stage = 3 + state?.extraOutbounds?.length;
    }

    // skip if we have not progressed
    if (activeStep === stage) return;

    // close the previous accordion step
    let newIndex = [];
    if (Array.isArray(accordionIndex)) {
      newIndex = newIndex.filter((i) => i !== stage - 1).concat(stage);
    } else {
      newIndex = [stage];
    }

    setAccordionIndex(newIndex);
    setActiveStep(stage);
  }, [accordionIndex, activeStep, setActiveStep, state]);

  const steps = [
    // ------------------------------ inbound ------------------------------
    {
      title: (
        <HStack justify="space-between" width="full">
          <Text>Inbound</Text>
          <LongPressTooltip label={txid} placement="top" maxW="none">
            <Link
              target="_blank"
              href={lib.txExplorerLink(txid, state?.inbound.asset)}
            >
              <Tag>
                <HStack>
                  <Text>... {txid?.slice(-6)}</Text>
                  <ExternalLinkIcon />
                </HStack>
              </Tag>
            </Link>
          </LongPressTooltip>
        </HStack>
      ),
      rows: [
        {
          label: "Age",
          value: lib.millisecondsToDHMS(age),
        },
        {
          label: "From",
          value: state?.inbound && (
            <LongPressTooltip
              label={state?.inbound.from}
              placement="top-end"
              maxW="none"
            >
              <Link
                target="_blank"
                href={lib.addressExplorerLink(
                  state?.inbound.from,
                  state?.inbound.asset,
                )}
              >
                <Flex justify="right">
                  <Text>
                    {lib.shortAddress(
                      state?.inbound.from,
                      state?.inbound.asset?.chain,
                    )}
                  </Text>
                  <ExternalLinkIcon ml={2} />
                </Flex>
              </Link>
            </LongPressTooltip>
          ),
        },
        state?.inbound.gas && {
          label: "Gas",
          value: (
            <LongPressTooltip
              fontSize="md"
              placement="top-end"
              label={lib.usdString(
                lib.amountToUSD(
                  state?.inbound.gas,
                  state?.inbound.gasAsset,
                  pools,
                ),
              )}
            >
              <Text>
                {state?.inbound.gas / 1e8} {state?.inbound.gasAsset?.symbol}
              </Text>
            </LongPressTooltip>
          ),
        },
        state?.swap.affiliateFee > 0 && {
          label: "Affiliate Fee",
          value: (
            <LongPressTooltip
              fontSize="md"
              placement="top-end"
              label={lib.usdString(
                (state?.swap.affiliateFee / 10000) * state?.inbound.usdValue,
              )}
            >
              <Text>{(state?.swap.affiliateFee / 100).toFixed(2)}%</Text>
            </LongPressTooltip>
          ),
        },
        state?.inbound.affiliate && {
          label: "Affiliate",
          value: (
            <Text>
              {lib.shortAddress(
                state?.inbound.affiliate,
                state?.inbound.asset.chain,
              )}
            </Text>
          ),
        },
        state?.inbound.asset?.chain !== "THOR" &&
          state?.inbound.asset?.type === types.AssetType.L1 &&
          state?.inbound.preObservations && {
            label: "Pre-Confirm Observations",
            value:
              !state?.inbound.finalisedHeight ||
              heightRef.current - state?.inbound.finalisedHeight <
                config.ProgressBlocks ? (
                <HStack justify="right">
                  <Text size="sm">
                    {state?.inbound.preObservations}/{network?.activeNodeCount}{" "}
                    nodes
                  </Text>
                </HStack>
              ) : (
                <Text size="sm">{state?.inbound.preObservations} nodes</Text>
              ),
          },
        lib.requiresConfirmations(state?.inbound.asset) && {
          label: "Confirmations",
          value: (
            <HStack>
              {state?.inbound.confirmations <
              state?.inbound.confirmationsRequired ? (
                <Progress
                  size="xs"
                  width="full"
                  hasStripe={
                    state?.inbound.confirmations <
                    state?.inbound.confirmationsRequired
                  }
                  colorScheme={
                    state?.inbound.confirmations >=
                    state?.inbound.confirmationsRequired
                      ? "green"
                      : "blue"
                  }
                  value={
                    (state?.inbound.confirmations /
                      state?.inbound.confirmationsRequired) *
                    100
                  }
                />
              ) : (
                <Spacer />
              )}
              <Text size="sm">
                {state?.inbound.confirmations}/
                {state?.inbound.confirmationsRequired} blocks
              </Text>
            </HStack>
          ),
        },
        state?.inbound.asset?.chain !== "THOR" &&
          state?.inbound.asset?.type === types.AssetType.L1 && {
            label: "Observations",
            value: (heightRef.current - state?.inbound.finalisedHeight <
              config.ProgressBlocks && (
              <HStack>
                <Progress
                  size="xs"
                  width="full"
                  hasStripe={
                    state?.inbound.observations < network?.activeNodeCount
                  }
                  colorScheme={
                    state?.inbound.observations >
                    (2 / 3) * network?.activeNodeCount
                      ? "green"
                      : "blue"
                  }
                  value={
                    (state?.inbound.observations / network?.activeNodeCount) *
                    100
                  }
                />
                <Text size="sm">
                  {state?.inbound.observations}/{network?.activeNodeCount} nodes
                </Text>
              </HStack>
            )) || <Text size="sm">{state?.inbound.observations} nodes</Text>,
          },
      ],
    },

    // ------------------------------ swap ------------------------------

    {
      title: "Swap",
      rows: [
        state?.swap.streaming.interval && {
          label: "Interval",
          value: (
            <Text size="sm">{state?.swap.streaming.interval} blocks/swap</Text>
          ),
        },
        state?.swap.streaming.memoQuantity && {
          label: "Quantity",
          value: (
            <Text size="sm">{state?.swap.streaming.memoQuantity} swaps</Text>
          ),
        },
        state?.swap.limit && {
          label: "Limit",
          value: (
            <Text size="sm">
              {(state?.swap.limit / 1e8).toLocaleString()}{" "}
              {state?.outbound.asset.symbol}
            </Text>
          ),
        },
        state?.inbound.done &&
          state?.swap.streaming.quantity && {
            label: "Stream",
            value: (
              <HStack>
                <Progress
                  size="xs"
                  width="full"
                  hasStripe={!state?.swap.done}
                  colorScheme={state?.swap.done ? "green" : "blue"}
                  value={
                    ((state?.swap.streaming.count ||
                      state?.swap.streaming.quantity) /
                      state?.swap.streaming.quantity) *
                    100
                  }
                />
                <Text size="sm">
                  {state?.swap.streaming.count ||
                    state?.swap.streaming.quantity}
                  /{state?.swap.streaming.quantity}
                </Text>
              </HStack>
            ),
          },
        state?.swap.liquidityFee && {
          label: "Liquidity Fee",
          value: (
            <LongPressTooltip
              fontSize="md"
              placement="top-end"
              label={lib.usdString(
                lib.amountToUSD(state?.swap.liquidityFee, lib.RuneAsset, pools),
              )}
            >
              <Text>
                {`${
                  state?.swap.liquidityFee / 1e8 > 1000
                    ? "~" +
                      Math.round(
                        state?.swap.liquidityFee / 1e8,
                      ).toLocaleString()
                    : state?.swap.liquidityFee / 1e8
                } RUNE (${(state?.swap.slip / 100).toFixed(2)}%)`}
              </Text>
            </LongPressTooltip>
          ),
        },
        state?.swap.aggregator.contract && {
          label: "Agg Contract",
          value: state?.swap.aggregator.contract.startsWith("0x") ? (
            <LongPressTooltip
              label={state?.swap.aggregator.contract}
              placement="top-end"
              maxW="none"
            >
              <Link
                target="_blank"
                href={lib.addressExplorerLink(
                  state?.swap.aggregator.contract,
                  state?.outbound.asset,
                )}
              >
                {lib.shortAddress(
                  state?.swap.aggregator.contract,
                  state?.outbound.asset.chain,
                )}
                <ExternalLinkIcon ml={2} />
              </Link>
            </LongPressTooltip>
          ) : (
            <LongPressTooltip
              label="(shortened)"
              placement="top-end"
              maxW="none"
            >
              <Text>{state?.swap.aggregator.contract}</Text>
            </LongPressTooltip>
          ),
        },
        state?.swap.aggregator.targetAsset && {
          label: "Agg Asset",
          value: state?.swap.aggregator.targetAsset.startsWith("0x") ? (
            <LongPressTooltip
              label={state?.swap.aggregator.targetAsset}
              placement="top-end"
              maxW="none"
            >
              <Link
                target="_blank"
                href={lib.addressExplorerLink(
                  state?.swap.aggregator.targetAsset,
                  state?.outbound.asset,
                )}
              >
                {lib.shortAddress(
                  state?.swap.aggregator.targetAsset,
                  state?.outbound.asset.chain,
                )}
                <ExternalLinkIcon ml={2} />
              </Link>
            </LongPressTooltip>
          ) : (
            <LongPressTooltip
              label="(shortened)"
              placement="top-end"
              maxW="none"
            >
              <Text>{state?.swap.aggregator.targetAsset}</Text>
            </LongPressTooltip>
          ),
        },
        state?.swap.aggregator.targetLimit && {
          label: "Agg Limit",
          value: <Text>{state?.swap.aggregator.targetLimit}</Text>,
        },
      ],
    },
  ];

  // ------------------------------ outbounds ------------------------------

  const allOutbounds = state?.outbound
    ? [
        ...[state.outbound],
        ...(state.extraOutbounds ? state.extraOutbounds : []),
      ]
    : [];

  const consolidatedOutbounds = allOutbounds.reduce((acc, outbound) => {
    const key = lib.assetChainSymbol(outbound.asset);
    if (acc[key]) {
      acc[key].amount += outbound.amount;
      acc[key].usdValue += outbound.usdValue;
    } else {
      acc[key] = { ...outbound }; // Clone the object to avoid side-effects
    }
    return acc;
  }, {});

  const summaryOutbounds = Object.values(consolidatedOutbounds);

  allOutbounds.map((outbound) => {
    steps.push({
      title: (
        <HStack justify="space-between" width="full">
          <Text>Outbound</Text>
          {activeStep >= 2 && outbound.txid && (
            <LongPressTooltip
              label={outbound.txid}
              placement="top-end"
              maxW="none"
            >
              <Link
                target="_blank"
                href={lib.txExplorerLink(outbound.txid, outbound.asset)}
              >
                <Tag>
                  <HStack>
                    <Text>... {outbound.txid?.slice(-6)}</Text>
                    <ExternalLinkIcon />
                  </HStack>
                </Tag>
              </Link>
            </LongPressTooltip>
          )}
        </HStack>
      ),
      rows: [
        {
          label: "Destination",
          value: outbound && (
            <LongPressTooltip
              label={outbound.to}
              placement="top-end"
              maxW="none"
            >
              <Link
                target="_blank"
                href={lib.addressExplorerLink(outbound.to, outbound.asset)}
              >
                {lib.shortAddress(outbound.to, outbound.asset?.chain)}
                <ExternalLinkIcon ml={2} />
              </Link>
            </LongPressTooltip>
          ),
        },
        (outbound.fee && {
          label: "Fee",
          value: (
            <LongPressTooltip
              fontSize="md"
              placement="top-end"
              label={lib.usdString(
                lib.amountToUSD(outbound.fee, outbound.feeAsset, pools),
              )}
            >
              <Text>
                {outbound.fee / 1e8} {outbound.feeAsset?.symbol}
              </Text>
            </LongPressTooltip>
          ),
        }) ||
          null,
        (outbound.asset?.chain !== "THOR" &&
          outbound.asset?.type !== types.AssetType.L1 &&
          !outbound.finalisedHeight &&
          outbound.maxGas && {
            label: "Max Gas",
            value: (
              <LongPressTooltip
                fontSize="md"
                placement="top-end"
                label={lib.usdString(
                  lib.amountToUSD(outbound.maxGas, outbound.maxGasAsset, pools),
                )}
              >
                <Text>
                  {outbound.maxGas / 1e8} {outbound.maxGasAsset.symbol}
                </Text>
              </LongPressTooltip>
            ),
          }) ||
          null,
        outbound.gas > 0 && {
          label: "Gas",
          value: (
            <LongPressTooltip
              fontSize="md"
              placement="top-end"
              label={lib.usdString(
                lib.amountToUSD(outbound.gas, outbound.gasAsset, pools),
              )}
            >
              <Text>
                {outbound.gas / 1e8} {outbound.gasAsset.symbol}
              </Text>
            </LongPressTooltip>
          ),
        },
        outbound.delayBlocks > 0 && {
          label: "Delay",
          value: (
            <HStack>
              <Progress
                size="xs"
                width="full"
                hasStripe={outbound.delayBlocksRemaining > 0}
                colorScheme={
                  outbound.delayBlocksRemaining === 0 ? "green" : "blue"
                }
                value={
                  ((outbound.delayBlocks - outbound.delayBlocksRemaining) /
                    outbound.delayBlocks) *
                  100
                }
              />
              <LongPressTooltip
                label={lib.millisecondsToDHMS(
                  outbound.delayBlocksRemaining * lib.blockMilliseconds("THOR"),
                )}
                placement="top-end"
              >
                <Text size="sm">
                  {outbound.delayBlocks - outbound.delayBlocksRemaining}/
                  {outbound.delayBlocks} blocks
                </Text>
              </LongPressTooltip>
            </HStack>
          ) || (
            <HStack justify="right">
              <Text size="sm">{outbound.delayBlocks} blocks</Text>
            </HStack>
          ),
        },
        outbound.asset?.chain !== "THOR" &&
          outbound.asset?.type === types.AssetType.L1 &&
          outbound.delayBlocksRemaining === 0 && {
            label: "Observations",
            value: (
              <HStack justifyContent="flex-end">
                {(heightRef.current - outbound.finalisedHeight <
                  config.ProgressBlocks ||
                  !outbound.observations) && (
                  <Progress
                    size="xs"
                    width="full"
                    hasStripe={outbound.observations < network?.activeNodeCount}
                    isIndeterminate={!outbound.observations}
                    colorScheme={
                      outbound.observations > (2 / 3) * network?.activeNodeCount
                        ? "green"
                        : "blue"
                    }
                    value={
                      (outbound.observations / network?.activeNodeCount) * 100
                    }
                  />
                )}
                {outbound.observations && (
                  <Text size="sm">
                    {outbound.observations}/{network?.activeNodeCount} nodes
                  </Text>
                )}
              </HStack>
            ),
          },
        outbound.delayBlocksRemaining === 0 &&
          lib.requiresConfirmations(outbound.asset) && {
            label: "Confirmations",
            value: (outbound.confirmations > 0 && (
              <LongPressTooltip
                label={lib.millisecondsToDHMS(
                  outbound.confirmations *
                    lib.blockMilliseconds(outbound.asset.chain),
                )}
                placement="top-end"
                maxW="none"
              >
                <Flex justify="right">
                  <Text size="sm">{outbound.confirmations} blocks</Text>
                </Flex>
              </LongPressTooltip>
            )) || <Progress width="full" size="xs" isIndeterminate />,
          },
      ],
    });

    return null;
  });

  // ------------------------------ progress ------------------------------

  let progress = null;
  if (eta > 0) {
    progress = (
      <HStack>
        <CircularProgress
          value={100 - (100 * eta) / startEtaRef.current}
          size="32px"
        />
        <Heading size="md">{eta ? lib.millisecondsToDHMS(eta) : "..."}</Heading>
      </HStack>
    );
  } else if (activeStep < 3) {
    progress = (
      <Button
        size="sm"
        isLoading
        colorScheme={state?.outbound.hasRefund ? "yellow" : "blue"}
        width="33%"
        spinner={<BeatLoader size={8} color="white" />}
      ></Button>
    );
  } else if (
    (state?.outbound.hasRefund && state?.outbound.hasSuccess) ||
    (!state?.outbound.hasSuccess && state?.outbound.hasMultipleSuccess)
  ) {
    progress = (
      <Tag colorScheme="orange" size="lg" variant="subtle">
        <Text>Partial Fill</Text>
      </Tag>
    );
  } else if (state?.outbound.hasRefund) {
    progress = (
      <LongPressTooltip label={state?.outbound.refundReason} placement="bottom">
        <Tag colorScheme="red" size="lg">
          <Text>Swap Refunded</Text>
        </Tag>
      </LongPressTooltip>
    );
  } else if (state?.outbound.hasSuccess) {
    progress = (
      <Tag colorScheme="green" size="lg" variant="subtle">
        <Text>Success</Text>
      </Tag>
    );
  }

  // ------------------------------ ui ------------------------------

  // return loading or unsupported if necessary
  if (!state) {
    return (
      <Card variant="outline" height="md" width="sm" p={3}>
        <Center height="full">
          <Spinner size="xl" />
        </Center>
      </Card>
    );
  } else if (!state.inbound.observed) {
    return (
      <Card variant="outline" height="md" width="sm" p={3}>
        <Center height="full">
          <VStack>
            <Text>Transaction not yet observed.</Text>
            <Spinner mt={8} size="xl" />
          </VStack>
        </Center>
      </Card>
    );
  } else if (state?.isOutbound) {
    return (
      <ChakraProvider theme={theme}>
        <AlertDialog isOpen={true}>
          <AlertDialogOverlay>
            <AlertDialogContent width="100%">
              <AlertDialogHeader fontSize="md" fontWeight="bold">
                <HStack justify="space-between">
                  <Text>Detected Outbound Transaction</Text>
                  <Button
                    size="sm"
                    colorScheme="blue"
                    onClick={() => {
                      window.location.pathname = state.inbound;
                    }}
                  >
                    Go to Inbound
                  </Button>
                </HStack>
              </AlertDialogHeader>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </ChakraProvider>
    );
  } else if (!state.isSwap || state.memo.includes("~")) {
    return <UnsupportedTransaction state={state} />;
  }

  return (
    <Box>
      {queryLogo && (
        <Flex justifyContent="center" m={6}>
          <Image
            src={`/logos/${queryLogo}`}
            maxHeight="50px"
            maxWidth="150px"
          />
        </Flex>
      )}
      <Card variant="outline" width="sm" p={3}>
        <HStack justify="space-between" width="full">
          {progress}
          <Spacer />
          <Link href={lib.runescanURL(`tx/${state.inbound.txid}`)} isExternal>
            <Button fontWeight="normal" size="sm" colorScheme="gray">
              <HStack>
                <Text>Runescan</Text>
                <ExternalLinkIcon />
              </HStack>
            </Button>
          </Link>
        </HStack>
        <Divider mt={3} mb={3} />
        <HStack pt={2} px={1} pb={0} justifyContent="space-between">
          <VStack maxWidth="45%">
            <Stat width="full">
              <StatLabel>
                <Flex align="center">
                  <Icon name={state?.inbound.icon} size={18} />
                  <Text ml={2}>
                    {lib.assetChainSymbol(state?.inbound.asset)}
                  </Text>
                </Flex>
              </StatLabel>
              <StatNumber ph={2}>
                <Text textOverflow="ellipsis" isTruncated>
                  {lib.localeString(state?.inbound.amount / 1e8, 8)}
                </Text>
              </StatNumber>
              <StatHelpText>
                {lib.usdString(state?.inbound.usdValue, true)}
              </StatHelpText>
            </Stat>
          </VStack>
          <Center>
            <ArrowRightIcon />
          </Center>
          <VStack maxWidth="45%" alignItems="flex-start" spacing="0">
            {summaryOutbounds.map((outbound, index) => (
              <Box width="full" key={index}>
                {index > 0 && <Divider mt={1} mb={3} />}
                <Stat width="full">
                  <StatLabel>
                    <Flex align="center">
                      <Icon name={outbound.icon} size={18} />
                      <Text ml={2}>{lib.assetChainSymbol(outbound.asset)}</Text>
                    </Flex>
                  </StatLabel>
                  <StatNumber>
                    <Text textOverflow={"ellipsis"} isTruncated>
                      {activeStep > 1 && outbound.amount
                        ? lib.localeString(outbound.amount / 1e8, 8)
                        : "..."}
                    </Text>
                  </StatNumber>
                  <StatHelpText>
                    {activeStep > 1 && outbound.usdValue
                      ? lib.usdString(outbound.usdValue, true)
                      : "..."}
                  </StatHelpText>
                </Stat>
              </Box>
            ))}
          </VStack>
        </HStack>
        <Divider mt={2} mb={3} />
        <Stepper size="sm" index={activeStep} gap="0">
          {steps.slice(0, 3).map((step, index) => (
            <Step key={index} gap="0">
              <StepIndicator bg="white">
                <StepStatus complete={<StepIcon />} />
              </StepIndicator>
              <StepSeparator _horizontal={{ ml: "0" }} />
            </Step>
          ))}
        </Stepper>
        <Accordion
          mt={3}
          index={accordionIndex}
          allowToggle
          onChange={setAccordionIndex}
        >
          {steps.map((step, index) => (
            <AccordionItem key={index}>
              <h2>
                <AccordionButton>
                  <Box
                    as="span"
                    flex="1"
                    color={
                      activeStep === index && activeStep <= 2 ? "" : "gray.400"
                    }
                    textAlign="left"
                  >
                    {step.title}
                  </Box>
                  {activeStep > 2 || activeStep > index ? (
                    <CheckIcon color="green.400" ml={3} mr={3} />
                  ) : activeStep === index ? (
                    <Spinner size="sm" ml={3} mr={3} />
                  ) : null}
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel p={2} bg="gray.50">
                <TableContainer>
                  <Table size="sm">
                    <Tbody>
                      {step.rows.map(
                        (row, index) =>
                          row && (
                            <Tr key={index}>
                              <HStack width="full">
                                <Th p={2}>{row.label}</Th>
                                <Box
                                  fontFamily="mono"
                                  fontSize="sm"
                                  width="full"
                                  textAlign="right"
                                >
                                  {row.value}
                                </Box>
                              </HStack>
                            </Tr>
                          ),
                      )}
                    </Tbody>
                  </Table>
                </TableContainer>
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      </Card>
    </Box>
  );
};

export default Swap;
