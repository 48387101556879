import axios from "axios";
import * as config from "./config";
import * as types from "./types";

////////////////////////////////////////////////////////////////////////////////////////
// Constants
////////////////////////////////////////////////////////////////////////////////////////

const RuneAsset = parseAsset("THOR.RUNE");

////////////////////////////////////////////////////////////////////////////////////////
// Requests
////////////////////////////////////////////////////////////////////////////////////////

function getThornode(path, params) {
  let headers = {};
  if (config.getHeight() > 0) {
    // if path has /thorchain prefix add height parameter
    if (path.startsWith("thorchain")) {
      params = { ...params, height: config.getHeight() };
    }

    // if path has /cosmos prefix add height header
    if (path.startsWith("cosmos")) {
      headers = { "X-Cosmos-Block-Height": config.getHeight() };
    }
  }

  let url = "https://thornode.ninerealms.com";

  // use archive node for historical heights
  if (config.getHeight()) {
    url = "https://thornode-archive.ninerealms.com";
  }

  if (config.getNetwork() === "stagenet") {
    url = "https://stagenet-thornode.ninerealms.com";
  }

  return axios.get(`${url}/${path}`, {
    params: params,
    headers: headers,
  });
}

function getNineRealmsAPI(path) {
  // unsupported on stagenet
  if (config.getNetwork() === "stagenet") {
    return new Promise();
  }

  let url = "https://api.ninerealms.com";
  return axios.get(`${url}/${path}`);
}

function getMidgard(path, params) {
  let url = "https://midgard.ninerealms.com";
  if (config.getNetwork() === "stagenet") {
    url = "https://stagenet-midgard.ninerealms.com";
  }

  return axios.get(`${url}/${path}`, {
    params: params,
  });
}

////////////////////////////////////////////////////////////////////////////////////////
// Common
////////////////////////////////////////////////////////////////////////////////////////

function isValidTxID(txid) {
  return txid && stripTxID(txid).length === 64;
}

function isValidAddress(address) {
  if (!address) return false;

  const addressPrefixes = [
    "0x",
    "L",
    "M",
    "1",
    "3",
    "bc1",
    "q",
    "p",
    "D",
    "cosmos1",
    "thor",
  ];
  if (addressPrefixes.some((p) => address.startsWith(p))) return true;

  return false;
}

function millisecondsToDHMS(ms, maxComponents = 4) {
  // Convert to seconds
  let seconds = Math.floor(ms / 1000);

  let days = Math.floor(seconds / 86400);
  seconds %= 86400;
  let hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  let minutes = Math.floor(seconds / 60);
  seconds = Math.floor(seconds % 60);

  const components = [];

  if (days > 0) {
    components.push(`${days}d`);
  }
  if (hours > 0) {
    if (components.length < maxComponents) {
      components.push(`${String(hours).padStart(2, "0")}h`);
    }
  }
  if (minutes > 0) {
    if (components.length < maxComponents) {
      components.push(`${String(minutes).padStart(2, "0")}m`);
    }
  }
  if (components.length < maxComponents) {
    components.push(`${String(seconds).padStart(2, "0")}s`);
  }

  return components.join(" ");
}

function runescanURL(path) {
  const url = `https://runescan.io/${path}`;
  const network = config.getNetwork();
  if (network) {
    return `${url}?network=${network}`;
  }
  return url;
}

function hashCode(str) {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    const char = str.charCodeAt(i);
    hash = (hash << 5) - hash + char;
  }
  return hash;
}

let vaultColors = {};

function colorizeVault(vault) {
  if (!vault) return null;

  if (vaultColors[vault]) {
    return `${vaultColors[vault]}.200`;
  }

  const allChakraColors = [
    "red",
    "orange",
    "green",
    "teal",
    "blue",
    "cyan",
    "purple",
  ];

  const hash = hashCode(vault);
  let index = Math.abs(hash) % allChakraColors.length;
  let selectedColor = allChakraColors[index];

  // iterate until we find an unused color
  const usedColors = Object.values(vaultColors);
  if (usedColors.length < allChakraColors.length) {
    while (usedColors.includes(selectedColor)) {
      index = (index + 1) % allChakraColors.length;
      selectedColor = allChakraColors[index];
    }
  }

  vaultColors[vault] = selectedColor;

  return `${vaultColors[vault]}.200`;
}

function stripTxID(txid) {
  // strip 0x prefix
  if (txid.startsWith("0x")) {
    txid = txid.slice(2);
  }
  return txid;
}

function pauseTooltip(pauseHeight, chainHeight) {
  if (pauseHeight <= chainHeight) {
    return "";
  }
  const diff = pauseHeight - chainHeight;
  const time = millisecondsToDHMS(diff * blockMilliseconds("THOR"), 2);
  return `Paused until ${pauseHeight} (~${time})`;
}

function haltTooltip(haltHeight, chainHeight) {
  if (haltHeight <= chainHeight) {
    return "";
  }

  const diff = haltHeight - chainHeight;
  const time = millisecondsToDHMS(diff * blockMilliseconds("THOR"), 2);
  return `Scheduled halt at height ${haltHeight} (~${time})`;
}

////////////////////////////////////////////////////////////////////////////////////////
// Chain Specific
////////////////////////////////////////////////////////////////////////////////////////

function shortAddress(address, chain) {
  // assume thorname if short address
  if (!address || address.length < 32) {
    return address;
  }

  switch (chain) {
    case "ETH":
    case "BASE":
    case "BSC":
    case "AVAX":
    case "BCH":
    case "DOGE":
    case "BTC":
      return address.slice(0, 2) + "..." + address.slice(-4);
    case "LTC":
    case "BNB":
      return address.slice(0, 3) + "..." + address.slice(-4);
    case "THOR":
      return address.slice(0, 4) + "..." + address.slice(-4);
    case "GAIA":
      return address.slice(0, 6) + "..." + address.slice(-4);
    default:
      return "";
  }
}

function txExplorerLink(txid, asset) {
  switch (asset?.type !== types.AssetType.L1 ? "THOR" : asset?.chain) {
    case "ETH":
      return `https://etherscan.io/tx/0x${txid}`;
    case "BASE":
      return `https://basescan.org/tx/0x${txid}`;
    case "BSC":
      return `https://bscscan.com/tx/0x${txid}`;
    case "BNB":
      return `https://explorer.binance.org/tx/${txid}`;
    case "AVAX":
      return `https://cchain.explorer.avax.network/tx/0x${txid}`;
    case "LTC":
      return `https://live.blockcypher.com/ltc/tx/${txid.toLowerCase()}`;
    case "BTC":
      return `https://mempool.space/tx/${txid.toLowerCase()}`;
    case "DOGE":
      return `https://live.blockcypher.com/doge/tx/${txid.toLowerCase()}`;
    case "THOR":
      return runescanURL(`tx/${txid}`);
    case "GAIA":
      return `https://www.mintscan.io/cosmos/txs/${txid}`;
    case "BCH": // no blockcypher explorer for BCH
      return `https://blockchain.com/bch/tx/${txid}`;
    default:
      return "";
  }
}

function addressExplorerLink(address, asset) {
  switch (asset?.type !== types.AssetType.L1 ? "THOR" : asset?.chain) {
    case "ETH":
      return `https://etherscan.io/address/${address}`;
    case "BASE":
      return `https://basescan.org/address/${address}`;
    case "BSC":
      return `https://bscscan.com/address/${address}`;
    case "AVAX":
      return `https://cchain.explorer.avax.network/address/${address}`;
    case "LTC":
      return `https://live.blockcypher.com/ltc/address/${address}`;
    case "BTC":
      return `https://mempool.space/address/${address}`;
    case "BCH":
      return `https://live.blockcypher.com/bch/address/${address}`;
    case "DOGE":
      return `https://live.blockcypher.com/doge/address/${address}`;
    case "THOR":
      return runescanURL(`address/${address}`);
    case "GAIA":
      return `https://www.mintscan.io/cosmos/account/${address}`;
    default:
      return "";
  }
}

function blockExplorerLink(chain, height) {
  switch (chain) {
    case "ETH":
      return `https://etherscan.io/block/${height}`;
    case "BASE":
      return `https://basescan.org/block/${height}`;
    case "BSC":
      return `https://bscscan.com/block/${height}`;
    case "BNB":
      return `https://explorer.binance.org/block/${height}`;
    case "AVAX":
      return `https://snowtrace.io/block/${height}`;
    case "LTC":
      return `https://live.blockcypher.com/ltc/block/${height}`;
    case "BTC":
      return `https://mempool.space/block/${height}`;
    case "DOGE":
      return `https://live.blockcypher.com/doge/block/${height}`;
    case "THOR":
      return runescanURL(`block/${height}`);
    case "GAIA":
      return `https://www.mintscan.io/cosmos/blocks/${height}`;
    case "BCH":
      return `https://blockchair.com/bitcoin-cash/block/${height}`;
    default:
      return "";
  }
}

function requiresConfirmations(asset) {
  if (!asset || asset?.type === types.AssetType.L1) {
    return false;
  }
  switch (asset.chain) {
    case "BTC":
    case "ETH":
    case "BASE":
    case "BCH":
    case "LTC":
    case "DOGE":
      return true;
    default:
      return false;
  }
}

// nativeTx returns a small amount of native tx data we are unable to retrieve from the
// default thornode and midgard endpoints.
async function nativeTx(txid, chain) {
  switch (chain) {
    case "THOR": {
      const res = await getThornode(`cosmos/tx/v1beta1/txs/${txid}`);

      // the first transfer event is gas
      const gasEvent = atob(
        res.data.tx_response.events
          .find((e) => e.type === "transfer")
          .attributes.find((a) => atob(a.key) === "amount").value,
      );

      // ensure gas ends in "rune" and remove the "rune" suffix
      const gas = gasEvent.endsWith("rune") ? gasEvent.slice(0, -4) : null;

      return {
        gas: gas,
        gasAsset: RuneAsset,
      };
    }
    default:
      return null;
  }
}

function blockMilliseconds(chain) {
  switch (chain) {
    case "BTC":
      return 600_000;
    case "BCH":
      return 600_000;
    case "LTC":
      return 150_000;
    case "DOGE":
      return 60_000;
    case "ETH":
      return 12_000;
    case "THOR":
      return 6_000;
    case "GAIA":
      return 6_000;
    case "AVAX":
      return 3_000;
    case "BSC":
      return 3_000;
    case "BASE":
      return 2_000;
    case "BNB":
      return 500;
    default:
      return 0;
  }
}

////////////////////////////////////////////////////////////////////////////////////////
// Memos
////////////////////////////////////////////////////////////////////////////////////////

function parseMemo(memo) {
  if (!memo) return {};

  // SWAP:ASSET:DESTADDR:LIM/INTERVAL/QUANTITY:AFFILIATE:FEE
  const parts = memo.split(":");
  const [limit, interval, quantity] = parts[3] ? parts[3].split("/") : [];

  return {
    type: parts[0] || null,
    asset: parts[1] || null,
    destAddr: parts[2] || null,
    limit: limit || null,
    interval: parseInt(interval) || null,
    quantity: parseInt(quantity) || null,
    affiliate: parts[4] || null,
    fee: parts[5] || null,
    aggregatorContract: parts[6] || null,
    aggregatorTargetAsset: parts[7] || null,
    aggregatorTargetLimit: parts[8] || null,
  };
}

////////////////////////////////////////////////////////////////////////////////////////
// Assets
////////////////////////////////////////////////////////////////////////////////////////

function separator(assetType) {
  switch (assetType) {
    case types.AssetType.L1:
      return ".";
    case types.AssetType.Synth:
      return "/";
    case types.AssetType.Trade:
      return "~";
    default:
      throw new Error("invalid asset type");
  }
}

function parseAsset(asset, pools) {
  if (!asset) return null;

  // determine asset type
  let type = types.AssetType.L1;
  if (asset.includes("/")) {
    type = types.AssetType.Synth;
  }
  if (asset.includes("~")) {
    type = types.AssetType.Trade;
  }
  const sep = separator(type);

  // fuzzy match
  let chain = "";
  let symbol = "";
  if (asset.split(sep).length === 1) {
    switch (asset.split(sep)[0].toLowerCase()) {
      case "a":
        chain = "AVAX";
        symbol = "AVAX";
        break;
      case "b":
        chain = "BTC";
        symbol = "BTC";
        break;
      case "c":
        chain = "BCH";
        symbol = "BCH";
        break;
      case "n":
        chain = "BNB";
        symbol = "BNB";
        break;
      case "s":
        chain = "BSC";
        symbol = "BNB";
        break;
      case "d":
        chain = "DOGE";
        symbol = "DOGE";
        break;
      case "e":
        chain = "ETH";
        symbol = "ETH";
        break;
      case "f":
        chain = "BASE";
        symbol = "ETH";
        break;
      case "g":
        chain = "GAIA";
        symbol = "ATOM";
        break;
      case "l":
        chain = "LTC";
        symbol = "LTC";
        break;
      case "r":
        chain = "THOR";
        symbol = "RUNE";
        break;
      default:
        chain = "";
        symbol = "";
    }
  } else {
    chain = asset.split(sep)[0].toUpperCase();
    symbol = asset.split(sep)[1].split("-")[0].toUpperCase();
  }

  let parsedAsset = {
    chain: chain,
    symbol: symbol,
    address: "",
    type: type,
  };

  if (asset.includes("-")) {
    parsedAsset.address = asset.split(sep)[1].split("-")[1];

    // attempt to fuzzy match address
    if (pools && !(assetString(parsedAsset) in pools)) {
      Object.values(pools).forEach((pool) => {
        if (
          pool.asset.chain === chain &&
          pool.asset.symbol === symbol &&
          pool.asset.address.endsWith(parsedAsset.address)
        ) {
          parsedAsset.address = pool.asset.address;
        }
      });
    }
  }

  return parsedAsset;
}

function assetString(asset) {
  const sep = separator(asset.type);
  let assetString = `${asset.chain}${sep}${asset.symbol}`;
  if (asset.address) {
    assetString += `-${asset.address}`;
  }
  return assetString;
}

function assetChainSymbol(asset) {
  if (!asset) return "";
  const sep = separator(asset.type);
  return `${asset.chain}${sep}${asset.symbol}`;
}

////////////////////////////////////////////////////////////////////////////////////////
// Amounts
////////////////////////////////////////////////////////////////////////////////////////

function usdPerRune(pools) {
  let asset = 0;
  let rune = 0;

  const usdPools = [
    "ETH.USDC-0XA0B86991C6218B36C1D19D4A2E9EB0CE3606EB48",
    "ETH.USDT-0XDAC17F958D2EE523A2206206994597C13D831EC7",
    "ETH.GUSD-0X056FD409E1D7A124BD7017459DFEA2F387B6D5CD",
    "ETH.LUSD-0X5F98805A4E8BE255A32880FDEC7F6728C6568BA0",
    "AVAX.USDC-0XB97EF9EF8734C71904D8002F8B6BC66DD9C48A6E",
    "AVAX.USDT-0X9702230A8EA53601F5CD2DC00FDBC13D4DF4A8C7",
    "BSC.USDC-0X8AC76A51CC950D9822D68B83FE1AD97B32CD580D",
    "BSC.USDT-0X55D398326F99059FF775485246999027B3197955",
  ];
  usdPools.forEach((pool) => {
    if (pools[pool]) {
      asset += parseInt(pools[pool].balance_asset);
      rune += parseInt(pools[pool].balance_rune);
    }
  });

  return asset / rune / 1e8;
}

function amountToUSD(amount, asset, pools) {
  if (!amount || !asset || !pools) return;

  if (asset.chain === "THOR" && asset.symbol === "RUNE") {
    return amount * usdPerRune(pools);
  }

  const l1Asset = { ...asset, type: types.AssetType.L1 };
  const pool = pools[assetString(l1Asset)];
  return pool ? (amount * parseInt(pool.asset_tor_price)) / 1e16 : 0;
}

function usdString(usd, round = false) {
  return usd
    ? usd.toLocaleString(undefined, {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: round ? 0 : 2,
      })
    : null;
}

function localeString(rune, decimals = 0) {
  return rune
    ? rune.toLocaleString(undefined, {
        maximumFractionDigits: decimals,
      })
    : null;
}

function convertDecimal(nativeAmount, decimals) {
  return parseFloat(nativeAmount) / Math.pow(10, decimals);
}

function formatDecimal(amount, decimals = 2) {
  return `${amount.toLocaleString("en-US", {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  })}`;
}

////////////////////////////////////////////////////////////////////////////////////////
// Exports
////////////////////////////////////////////////////////////////////////////////////////

export {
  RuneAsset,
  getThornode,
  getNineRealmsAPI,
  getMidgard,
  isValidTxID,
  isValidAddress,
  millisecondsToDHMS,
  runescanURL,
  hashCode,
  colorizeVault,
  stripTxID,
  pauseTooltip,
  haltTooltip,
  shortAddress,
  txExplorerLink,
  addressExplorerLink,
  blockExplorerLink,
  requiresConfirmations,
  nativeTx,
  blockMilliseconds,
  parseMemo,
  parseAsset,
  assetString,
  assetChainSymbol,
  usdPerRune,
  amountToUSD,
  usdString,
  localeString,
  convertDecimal,
  formatDecimal,
};
