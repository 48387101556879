import {
  Card,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

import Nodes from "./Nodes";
import Economy from "./Economy";
import Gas from "./Gas";
import ChainLag from "./ChainLag";

import "@fontsource/roboto";

const Status = ({
  height,
  mimir,
  network,
  thornodeNetwork,
  outboundFees,
  inboundAddresses,
  pools,
  supply,
  lendingBalance,
  runepool,
  nodes,
  upgrades,
  bifrost,
}) => {
  return (
    <Card variant="outline" my={3} p={2}>
      <Tabs>
        <TabList>
          <Tab py={1} px={2}>
            Nodes
          </Tab>
          <Tab py={1} px={2}>
            Economy
          </Tab>
          <Tab py={1} px={2}>
            Gas
          </Tab>
          <Tab py={1} px={2}>
            Chain Lag
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={1} pb={0}>
            <Nodes
              height={height}
              mimir={mimir}
              network={network}
              thornodeNetwork={thornodeNetwork}
              nodes={nodes}
              upgrades={upgrades}
            />
          </TabPanel>
          <TabPanel px={1} pb={0}>
            <Economy
              network={network}
              pools={pools}
              supply={supply}
              lendingBalance={lendingBalance}
              runepool={runepool}
            />
          </TabPanel>
          <TabPanel px={1} pb={0}>
            <Gas
              outboundFees={outboundFees}
              inboundAddresses={inboundAddresses}
              pools={pools}
            />
          </TabPanel>
          <TabPanel px={1} pb={0}>
            <ChainLag bifrost={bifrost} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Card>
  );
};

export default Status;
